
[data-amplify-theme] {
    --amplify-colors-background-primary: transparent;
    --amplify-colors-background-secondary: #ffffff;
    --amplify-colors-primary-10: #111827;
    --amplify-colors-primary-80: #22C55E; /* botão e tag */
    --amplify-colors-primary-90: #126230; /* hover botão */
    --amplify-colors-primary-100: #111827;
    --amplify-components-liveness-camera-module-background-color: #111827;
    --amplify-colors-font-inverse: white;
}

body{
    background-color: transparent;
}

button {
    color: #22C55E;
}

.amplify-liveness-toast--primary {}

.amplify-liveness-start-screen-warning {
    background-color: #22C55E;
}

.amplify-button--primary{
    color: #111827;
}

.amplify-liveness-toast {
    padding: 0 1rem;
    border-radius: 1rem;
}

.amplify-liveness-toast__message {
    color:white !important;
}

.amplify-text {
    color: black;
}

.amplify-liveness-toast--large {
    font-size: 1.2rem;
    border-radius: 2rem;
}

.amplify-liveness-popover__container {
    color: white;
    width: auto;
}